@import url(//fonts.googleapis.com/css?family=Lato:400,700|Raleway:400,700|Nunito+Sans:300,400,600);
html,
body,
#root {
  min-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.skin-black-light .main-header .logo,
.info-box-text,
.header,
.big {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

body {
  color: '#121427';
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

