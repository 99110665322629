@import url('//fonts.googleapis.com/css?family=Lato:400,700|Raleway:400,700|Nunito+Sans:300,400,600');

html,
body,
#root {
  min-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.skin-black-light .main-header .logo,
.info-box-text,
.header,
.big {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

body {
  color: '#121427';
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-weight: 300;
}
